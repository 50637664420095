import { useQueryClient } from "@tanstack/react-query";
import shopifyApiClient from "../../../api/shopify";

export const usePrefetchProductVariants = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery({
    queryKey: ["shopify"],
    queryFn: async () => shopifyApiClient.getProductVariants(),
  });
};
