import { useTheme } from "@mui/material";
import DSBox from "../system-design/DSBox";
import DSTypography from "../system-design/DSTypography";
import { useGiftContext } from "./Context/GiftContextProvider";
import { isIOS, isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import DSSwitch from "../system-design/DSSwitch";
import giftClient from "../../api/gift";
import KlydoOrderForm from "./KlydoOrderForm";
import KlydoPreview from "./KlydoPreview";
import { generateRandomNumberString, useHistory } from "../../utils";

const variantToImage = (woodType: string) => {
  if (woodType.toLowerCase().includes("walnut")) {
    return "https://res.cloudinary.com/klydoclock/image/upload/v1733216572/klydo_display_x_klydo_side_2_x_walnut_x_2000X2000_x_white_pup8j1.png";
  } else {
    return "https://res.cloudinary.com/klydoclock/image/upload/v1733216572/klydo_display_master_square_x_klydo_side_2_x_oak_x_2000X2000_x_white_background_rnvkcm.png";
  }
};

const GiftProductPage = () => {
  const theme = useTheme();
  const { setQueryParam } = useHistory();
  const { klydoGiftProps, selectedKlydoGreet } = useGiftContext();
  const [giftId, setGiftId] = useState("");
  useEffect(() => {
    const randomId = generateRandomNumberString();
    setGiftId(randomId);
    const orderId = `ShopifyRedirect${randomId}`;
    setQueryParam("orderId", orderId, false);
  }, []);
  const [woodType, setWoodType] = useState("");
  const [showGreeting, setShowGreeting] = useState(false);
  const handleToggle = (event) => {
    setShowGreeting(event.target.checked);
  };

  const handleBuyNow = async (productVariantId: string, quantity: number) => {
    const orderId = `ShopifyRedirect${giftId}`;
    await giftClient.createGiftKlydo({
      ...klydoGiftProps,
      orderId: orderId,
      giftId: giftId,
    });
    // const imageUrl = variantToImage(woodType);
    const checkoutUrl = `https://www.klydoclock.com/cart/add?id=${productVariantId}&quantity=${quantity}&properties[gift_id]=${orderId}`;
    if (window.self !== window.top) {
      window.parent.location.href = checkoutUrl;
    } else {
      window.location.href = checkoutUrl;
    }
  };

  return (
    <DSBox
      sx={{
        paddingTop: { xs: 0, md: 13 },
        justifyContent: "start",
        paddingBottom: { xs: 3, md: "0px" },
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <DSBox
        gap={isMobile ? 0.5 : 20}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row-reverse" },
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <DSBox
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginTop: { xs: 0, md: -9 },
          }}
        >
          <DSBox>
            <DSTypography
              lineHeight={1}
              fontStyle={"bold"}
              fontSize={isMobile ? 35 : 47.52}
              fontWeight={400}
              fontFamily={"Gilroy-Bold"}
              maxWidth={isMobile ? "350px" : "400px"}
            >
              Almost There!
            </DSTypography>
          </DSBox>
          <DSBox py={1} />
          <DSBox sx={{ paddingBottom: { xs: 2 } }}>
            <DSTypography
              fontSize={isMobile ? 15 : 18}
              fontWeight={400}
              fontFamily={"Gilroy-Medium"}
              maxWidth={isMobile ? "330px" : "400px"}
            >
              Almost There! Select the Klydoclock(s) you want to add your
              KlydoGreet to and complete your order. We can’t wait to hear all
              about their reaction.
            </DSTypography>
          </DSBox>
          {!isMobile && (
            <KlydoOrderForm
              handleBuyNow={handleBuyNow}
              setWoodType={(value: string) => setWoodType(value)}
            />
          )}
        </DSBox>
        <DSBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: isMobile ? "200px" : "290px",
              height: isMobile ? "330px" : "483px",
              position: "relative",
            }}
          >
            <DSBox py={1} />
            <KlydoPreview
              gift={klydoGiftProps}
              klydo={selectedKlydoGreet}
              isToggled={showGreeting}
              woodType={woodType}
            />
          </div>
          <DSBox py={2} />
          <DSBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              width: "100%",
            }}
          >
            <DSTypography
              fontWeight={400}
              fontFamily={"Gilroy-SemiBold"}
              fontSize={"16px"}
              color={showGreeting ? "#A5A5A5" : "#FFFFFF"}
            >
              Greet
            </DSTypography>

            <DSSwitch
              checked={showGreeting}
              onChange={handleToggle}
              sx={{ zIndex: theme.zIndex.tooltip }}
            />

            <DSTypography
              fontWeight={400}
              fontFamily={"Gilroy-SemiBold"}
              fontSize={"16px"}
              color={showGreeting ? "#FFFFFF" : "#A5A5A5"}
            >
              Message
            </DSTypography>
          </DSBox>
        </DSBox>

        <DSBox py={1} />
        {isMobile && (
          <KlydoOrderForm
            handleBuyNow={handleBuyNow}
            setWoodType={(value: string) => setWoodType(value)}
          />
        )}
      </DSBox>
    </DSBox>
  );
};
export default GiftProductPage;
