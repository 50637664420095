import { useEffect } from "react";

const useDisableZoom = () => {
  useEffect(() => {
    const metaTag = document.querySelector(
      'meta[name="viewport"]',
    ) as HTMLMetaElement;

    if (!metaTag) {
      const newMetaTag = document.createElement("meta");
      newMetaTag.name = "viewport";
      newMetaTag.content =
        "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no";
      document.head.appendChild(newMetaTag);
    } else {
      metaTag.content =
        "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no";
    }

    return () => {
      if (metaTag) {
        metaTag.content = "width=device-width, initial-scale=1.0";
      }
    };
  }, []);
};

export default useDisableZoom;
