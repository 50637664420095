import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";

export const resize = (url: string, size: number) => {
  if (!url) return;
  if (url.includes("image/upload") || url.includes("video/upload")) {
    return url.replace("/upload/", "/upload/c_thumb,w_" + size + "/");
  }
  return url.replace("/klydoclock/", "/klydoclock/c_thumb,w_" + size + "/");
};

export type ParseUrlInput = {
  url: string;
  isStatic?: boolean;
  size?: number;
};

export const parseCloudinaryUrl = (input: ParseUrlInput) => {
  let { url } = input;
  if (!url) return;
  const { isStatic, size } = input;
  const type = url.split(".").pop();
  const params = `c_scale,w_${size}`;
  const webpConversionParams = "fl_animated,fl_awebp,e_loop";
  const framesParams = "so_auto";
  if (type === "mp4") {
    if (isStatic) {
      url = url.replace("mp4", "jpg");
      if (url!.includes("image/upload") || url.includes("video/upload")) {
        url = url.replace("/upload/", `/upload/${framesParams}/`);
      } else {
        url = url.replace("/klydoclock/", `/klydoclock/${framesParams}/`);
      }
    }
    if (size) {
      let sizeParams = params;
      if (type === "mp4" && !isStatic) {
        url = url.replace("mp4", "webp");
        sizeParams = `${params}/${webpConversionParams}`;
      }
      if (url!.includes("image/upload") || url.includes("video/upload")) {
        url = url.replace("/upload/", `/upload/${sizeParams}/`);
      } else {
        url = url.replace("/klydoclock/", `/klydoclock/${sizeParams}/`);
      }
    }
  }
  return url;
};

export const useHistory = () => {
  const location = useLocation();
  const search = queryString.parse(location.search);

  const navigate = useNavigate();
  const buildPath = (
    path: string | { pathname: string; search: string },
  ): string => {
    if (typeof path === "string") return path;
    const searchUrl = path.search?.startsWith("?")
      ? path.search
      : `?${path.search}`;
    return `${path.pathname}${searchUrl}`;
  };
  const push = (path: string | { pathname: string; search: string }): void =>
    navigate(buildPath(path), { replace: false });

  const goBack = (): void => {
    const canGoBack = history?.state?.idx > 0;
    if (!!canGoBack) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  const replace = (path: string | { pathname: string; search: string }): void =>
    navigate(buildPath(path), { replace: true });
  const setQueryParam = (param, value, pushAndNotReplace) => {
    const newSearch = { ...search };
    newSearch[param] = value;
    if (pushAndNotReplace) {
      push({
        pathname: location.pathname,
        search: queryString.stringify(newSearch),
      });
    } else {
      replace({
        pathname: location.pathname,
        search: queryString.stringify(newSearch),
      });
    }
  };
  return { push, goBack, replace, setQueryParam, search };
};

const adjectives = [
  "afraid",
  "ancient",
  "angry",
  "average",
  "bad",
  "big",
  "bitter",
  "black",
  "blue",
  "brave",
  "breezy",
  "bright",
  "brown",
  "calm",
  "chatty",
  "chilly",
  "clever",
  "cold",
  "cowardly",
  "cuddly",
  "curly",
  "curvy",
  "dangerous",
  "dry",
  "dull",
  "empty",
  "evil",
  "fast",
  "fat",
  "fluffy",
  "foolish",
  "fresh",
  "friendly",
  "funny",
  "fuzzy",
  "gentle",
  "giant",
  "good",
  "great",
  "green",
  "grumpy",
  "happy",
  "hard",
  "heavy",
  "helpless",
  "honest",
  "horrible",
  "hot",
  "hungry",
  "itchy",
  "jolly",
  "kind",
  "lazy",
  "light",
  "little",
  "loud",
  "lovely",
  "lucky",
  "massive",
  "mean",
  "mighty",
  "modern",
  "moody",
  "nasty",
  "neat",
  "nervous",
  "new",
  "nice",
  "odd",
  "old",
  "orange",
  "ordinary",
  "perfect",
  "pink",
  "plastic",
  "polite",
  "popular",
  "pretty",
  "proud",
  "purple",
  "quick",
  "quiet",
  "rare",
  "red",
  "rotten",
  "rude",
  "selfish",
  "serious",
  "shaggy",
  "sharp",
  "short",
  "shy",
  "silent",
  "silly",
  "slimy",
  "slippery",
  "smart",
  "smooth",
  "soft",
  "sour",
  "spicy",
  "splendid",
  "spotty",
  "stale",
  "strange",
  "strong",
  "stupid",
  "sweet",
  "swift",
  "tall",
  "tame",
  "tasty",
  "tender",
  "terrible",
  "thin",
  "tidy",
  "tiny",
  "tough",
  "tricky",
  "ugly",
  "unlucky",
  "warm",
  "weak",
  "wet",
  "white",
  "wicked",
  "wise",
  "witty",
  "wonderful",
  "yellow",
  "young",
];
const adjectivesSize = BigInt(adjectives.length);
const animals = [
  "ape",
  "baboon",
  "badger",
  "bat",
  "bear",
  "bird",
  "bobcat",
  "bulldog",
  "bullfrog",
  "cat",
  "catfish",
  "cheetah",
  "chicken",
  "chipmunk",
  "cobra",
  "cougar",
  "cow",
  "crab",
  "deer",
  "dingo",
  "dodo",
  "dog",
  "dolphin",
  "donkey",
  "dragon",
  "dragonfly",
  "duck",
  "eagle",
  "earwig",
  "eel",
  "elephant",
  "emu",
  "falcon",
  "fireant",
  "firefox",
  "fish",
  "fly",
  "fox",
  "frog",
  "gecko",
  "goat",
  "goose",
  "grasshopper",
  "horse",
  "hound",
  "husky",
  "impala",
  "insect",
  "jellyfish",
  "kangaroo",
  "ladybug",
  "liger",
  "lion",
  "lionfish",
  "lizard",
  "mayfly",
  "mole",
  "monkey",
  "moose",
  "moth",
  "mouse",
  "mule",
  "newt",
  "octopus",
  "otter",
  "owl",
  "panda",
  "panther",
  "parrot",
  "penguin",
  "pig",
  "puma",
  "pug",
  "quail",
  "rabbit",
  "rat",
  "rattlesnake",
  "robin",
  "seahorse",
  "sheep",
  "shrimp",
  "skunk",
  "sloth",
  "snail",
  "snake",
  "squid",
  "starfish",
  "stingray",
  "swan",
  "termite",
  "tiger",
  "treefrog",
  "turkey",
  "turtle",
  "vampirebat",
  "walrus",
  "warthog",
  "wasp",
  "wolverine",
  "wombat",
  "yak",
  "zebra",
];
const animalsSize = BigInt(animals.length);
const nums = [
  0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
  42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
  61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79,
  80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98,
  99, 100,
];
const numsSize = BigInt(nums.length);

export const statesList = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
];

export const valueLabelState = (val: string): string => {
  const st = statesList.find((s) => s.value === val);
  if (st) return st.label;
  else return "not found";
};

export const labelValueState = (lbl: string): string => {
  const st = statesList.find((s) => s.label === lbl);
  if (st) return st.value;
  else return "not found";
};

export function friendlyFromHex(id: string | undefined) {
  if (!id) return "missing";
  id = id.replaceAll("-", "");
  const num = BigInt("0x" + id);
  return `${adjectives[Number(num % adjectivesSize)]}-${animals[Number(num % animalsSize)]}-${nums[Number(num % numsSize)]}`;
}

export function friendlyIdFromString(id: string) {
  const adjectives = [
    "afraid",
    "ancient",
    "angry",
    "average",
    "bad",
    "big",
    "bitter",
    "black",
    "blue",
    "brave",
    "breezy",
    "bright",
    "brown",
    "calm",
    "chatty",
    "chilly",
    "clever",
    "cold",
    "cowardly",
    "cuddly",
    "curly",
    "curvy",
    "dangerous",
    "dry",
    "dull",
    "empty",
    "evil",
    "fast",
    "fat",
    "fluffy",
    "foolish",
    "fresh",
    "friendly",
    "funny",
    "fuzzy",
    "gentle",
    "giant",
    "good",
    "great",
    "green",
    "grumpy",
    "happy",
    "hard",
    "heavy",
    "helpless",
    "honest",
    "horrible",
    "hot",
    "hungry",
    "itchy",
    "jolly",
    "kind",
    "lazy",
    "light",
    "little",
    "loud",
    "lovely",
    "lucky",
    "massive",
    "mean",
    "mighty",
    "modern",
    "moody",
    "nasty",
    "neat",
    "nervous",
    "new",
    "nice",
    "odd",
    "old",
    "orange",
    "ordinary",
    "perfect",
    "pink",
    "plastic",
    "polite",
    "popular",
    "pretty",
    "proud",
    "purple",
    "quick",
    "quiet",
    "rare",
    "red",
    "rotten",
    "rude",
    "selfish",
    "serious",
    "shaggy",
    "sharp",
    "short",
    "shy",
    "silent",
    "silly",
    "slimy",
    "slippery",
    "smart",
    "smooth",
    "soft",
    "sour",
    "spicy",
    "splendid",
    "spotty",
    "stale",
    "strange",
    "strong",
    "stupid",
    "sweet",
    "swift",
    "tall",
    "tame",
    "tasty",
    "tender",
    "terrible",
    "thin",
    "tidy",
    "tiny",
    "tough",
    "tricky",
    "ugly",
    "unlucky",
    "warm",
    "weak",
    "wet",
    "white",
    "wicked",
    "wise",
    "witty",
    "wonderful",
    "yellow",
    "young",
  ];
  const animals = [
    "ape",
    "baboon",
    "badger",
    "bat",
    "bear",
    "bird",
    "bobcat",
    "bulldog",
    "bullfrog",
    "cat",
    "catfish",
    "cheetah",
    "chicken",
    "chipmunk",
    "cobra",
    "cougar",
    "cow",
    "crab",
    "deer",
    "dingo",
    "dodo",
    "dog",
    "dolphin",
    "donkey",
    "dragon",
    "dragonfly",
    "duck",
    "eagle",
    "earwig",
    "eel",
    "elephant",
    "emu",
    "falcon",
    "fireant",
    "firefox",
    "fish",
    "fly",
    "fox",
    "frog",
    "gecko",
    "goat",
    "goose",
    "grasshopper",
    "horse",
    "hound",
    "husky",
    "impala",
    "insect",
    "jellyfish",
    "kangaroo",
    "ladybug",
    "liger",
    "lion",
    "lionfish",
    "lizard",
    "mayfly",
    "mole",
    "monkey",
    "moose",
    "moth",
    "mouse",
    "mule",
    "newt",
    "octopus",
    "otter",
    "owl",
    "panda",
    "panther",
    "parrot",
    "penguin",
    "pig",
    "puma",
    "pug",
    "quail",
    "rabbit",
    "rat",
    "rattlesnake",
    "robin",
    "seahorse",
    "sheep",
    "shrimp",
    "skunk",
    "sloth",
    "snail",
    "snake",
    "squid",
    "starfish",
    "stingray",
    "swan",
    "termite",
    "tiger",
    "treefrog",
    "turkey",
    "turtle",
    "vampirebat",
    "walrus",
    "warthog",
    "wasp",
    "wolverine",
    "wombat",
    "yak",
    "zebra",
  ];
  const nums = [
    0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
    60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78,
    79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97,
    98, 99, 100,
  ];
  const adjectivesSize = BigInt(adjectives.length);
  const animalsSize = BigInt(animals.length);
  const numsSize = BigInt(nums.length);
  const primes = [
    7, 11, 13, 19, 23, 29, 31, 37, 41, 43, 47, 53, 59, 61, 67, 71, 73, 79, 83,
    89, 101, 103, 107, 109, 113, 127, 137, 139, 149, 151, 157, 163, 167, 173,
    179, 181, 191, 193, 197, 199, 211, 223, 227,
  ];
  id = id.substring(5);
  let num = BigInt(1);
  for (let i = 0; i < id.length; i++) {
    const index = id[i].toUpperCase().charCodeAt(0) - 48;
    try {
      num *= BigInt(primes[index]);
    } catch (e) {
      console.log(e);
    }
    num += BigInt(index);
  }
  return `${adjectives[Number(num % adjectivesSize)]}-${animals[Number(num % animalsSize)]}-${nums[Number(num % numsSize)]}`;
}

export const getRandomTag = (): string => {
  let rndTag = "$p_";
  const chars = "abcdefghijklmnopqrstuvwxyz";
  for (let i = 0; i < 5; i++) {
    const rndIndex = Math.floor(Math.random() * chars.length);
    rndTag += chars.charAt(rndIndex);
  }
  return rndTag;
};

export const percentToPresent = (num: number): string => {
  return (
    (num >= 10
      ? Math.round(num)
      : num > 2
        ? Math.round(num * 10) / 10
        : Math.round(num * 100) / 100) + "%"
  );
};

export const socialMediaList = [
  "instagram",
  "linktree",
  "twitter",
  "facebook",
  "tiktok",
  "behance",
  "dribbble",
  "youtube",
  "patreon",
  "tumblr",
  "deviantart",
  "artstation",
  "wikipedia",
  "imdb",
  "vimeo",
  "pinterest",
  "opensea",
  "rarible",
  "superrare",
  "foundation",
  "nifty",
];

export function getContrastYIQ(hexcolor: string) {
  if (!hexcolor) return "black";
  var r = parseInt(hexcolor.substring(1, 3), 16);
  var g = parseInt(hexcolor.substring(3, 5), 16);
  var b = parseInt(hexcolor.substring(5, 7), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
}
type ViewType = "video" | "image" | "none";
const imgTypes = [
  "jpg",
  "gif",
  "avif",
  "apng",
  "png",
  "ico",
  "cur",
  "jpeg",
  "jfif",
  "pjpeg",
  "pjp",
  "tif",
  "webp",
  "bmp",
];
const videoTypes = ["mp4", "mpge", "avi", "mov", "mkv", "webm", "ogg", "ogv"];
export function isVideo(url: string): ViewType {
  if (url.startsWith("data"))
    return url.startsWith("data:video")
      ? "video"
      : url.startsWith("data:image")
        ? "image"
        : "none";
  const arr = url.split(".");
  const typ = arr[arr.length - 1];
  return imgTypes.includes(typ)
    ? "image"
    : videoTypes.includes(typ)
      ? "video"
      : "none";
}

export const getUploadResDetails = (result: any): number => {
  const format: string = result.format;
  var frames: number = NaN;
  if (imgTypes.includes(format)) {
    frames = Number.isNaN(Number(result.pages)) ? 1 : Number(result.pages);
  } else if (videoTypes.includes(format)) {
    frames = Number.isNaN(Number(result.nb_frames))
      ? Number(result.frame_rate) * Number(result.duration)
      : Number(result.nb_frames);
  } else return 0;
  if (
    Number.isNaN(result.height) ||
    Number.isNaN(result.width) ||
    Number.isNaN(frames)
  )
    return 0;
  const h = Number(result.height);
  const w = Number(result.width);
  return (
    (frames * (h <= 1080 ? h : 1080) * (w <= 1080 ? w : 1080) * 4) /
    (1024 * 1024)
  );
};

export const generateRandomNumberString = () => {
  let result = "";
  for (let i = 0; i < 7; i++) {
    result += Math.floor(Math.random() * 10);
  }
  return result;
};
