import { Klydo } from "../context/klydo/KlydoTypes";
import { baseClient } from "./baseRequest";

export type KlydosGift = {
  category: string;
  klydos: Klydo[];
};

export type KlydoGiftProps = {
  giftId: string;
  orderId: string;
  clockId: string;
  klydoId: string;
  senderName: string;
  date?: number;
  senderImageUrl?: string;
  greetingContent?: string;
};

export type GiftCategory = {
  name: string;
  categoryImageUrl: string;
  klydoIds: string[];
  klydos: Klydo[];
};

export type GiftClient = {
  getOrderGift(orderId: string): Promise<KlydoGiftProps & { klydo: Klydo }>;
  createGiftKlydo: (input: KlydoGiftProps) => Promise<void>;
  listCategories: () => Promise<GiftCategory[]>;
};

const giftClient: GiftClient = {
  getOrderGift: async (orderId) => {
    return baseClient.get({
      url: `/gift/get-order-gift?order-id=${orderId}`,
    });
  },
  createGiftKlydo: async (input) => {
    return baseClient.post({
      url: "/gift/add-gift",
      body: input,
    });
  },
  listCategories: async () => {
    return baseClient.get({
      url: "/gift-categories",
    });
  },
};

export default giftClient;
