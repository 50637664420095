import useEditorQuery from "../../../hooks/useEditorQuery";
import shopifyApiClient from "../../../api/shopify";

const useProductVariants = () => {
  return useEditorQuery({
    queryKey: ["shopify"],
    queryFn: async () => shopifyApiClient.getProductVariants(),
  });
};

export default useProductVariants;
