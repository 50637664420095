import { baseClient } from "./baseRequest";

export type WoodType = {
  name: string;
  price: number;
};

export type VariantOption = {
  name: string;
  values: WoodType[];
};

export type Variant = {
  id: string;
  variantValues: { [key: string]: string };
};

export type ProductVariants = {
  options: VariantOption[];
  variants: Variant[];
};

export type ShopifyApiClient = {
  getProductVariants: () => Promise<ProductVariants>;
};

const shopifyApiClient: ShopifyApiClient = {
  getProductVariants: async () => {
    return baseClient.get({
      url: `/shopify/get-product-variants`,
    });
  },
};

export default shopifyApiClient;
